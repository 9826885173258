/* eslint-disable react/jsx-props-no-spreading */
import 'styles/globals.css'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import { GlobalContextProvider } from 'context/GlobalContextProvider'

const GA_MEASUREMENT_ID = 'G-L10Y1LQR4Z'

function App({ Component, pageProps }: AppProps) {

  const router = useRouter()

  useEffect(() => {

    // Initialize GA4
    ReactGA.initialize(GA_MEASUREMENT_ID)

    // Track page views
    const handleRouteChange = (url:string) => {
      ReactGA.send({ hitType: 'pageview', page: url })
    }

    // Listen to route changes
    router.events.on('routeChangeComplete', handleRouteChange)

    // Clean up the event listener
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <GlobalContextProvider>
      <Component {...pageProps} />
    </GlobalContextProvider>
  )
}

export default appWithTranslation(App)
